<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.operario') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.nuevooperario') }}</h1>
                    </div>
                    <div class="col-sm-6">
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <operario-foto :id="id" :operario="operario" />
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                               <!-- <li class="list-group-item">
                                    <b>{{ $t('general.expedientesactivos') }}</b> <a class="float-right">5</a>
                                </li>-->
                                <li class="list-group-item">
                                    <b>{{ $t('general.visitaspendientes') }}</b> <a
                                        class="float-right">{{operario.siguientes_citas}}</a>
                                </li>
                                <!-- <li class="list-group-item">
                                    <b>{{ $t('general.visitascaducadas') }}</b> <a class="float-right">1</a>
                                </li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                        <h5>{{ $t('general.ultimamodificacion') }}</h5>
                        <p>{{ operario.fecha_actualiza_operario }}</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#operario_datos"
                                        data-toggle="tab">{{ $t('general.datos') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_marcas" data-toggle="tab">{{
                                        $t('general.marcas') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_docs" data-toggle="tab">{{
                                        $t('general.docrequerida') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_tipos_servicio"
                                        data-toggle="tab">{{ $t('general.tiposservicio') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_companias" data-toggle="tab">{{
                                        $t('general.companias') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#operario_especialidades"
                                        data-toggle="tab">{{ $t('general.especialidades') }}</a></li>


                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="operario_datos">
                            <operario-datos :id="id" :operario="operario" />
                        </div>
                        <div class="tab-pane" id="operario_marcas">
                            <operario-marcas :id="id" :operario="operario" />
                        </div>
                        <div class="tab-pane" id="operario_docs">
                            <operario-documentacion :id="id" :operario="operario" />
                        </div>
                        <div class="tab-pane" id="operario_tipos_servicio">
                            <operario-tiposservicio :id="id" :operario="operario" />
                        </div>
                        <div class="tab-pane" id="operario_companias">
                            <operario-companyias :id="id" :operario="operario" />
                        </div>
                        <div class="tab-pane" id="operario_especialidades">
                            <operario-especialidades :id="id" :operario="operario" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi'
import OperarioDatos from './datos/OperarioDatos.vue'
import OperarioFoto from './OperarioFoto.vue'
import OperarioMarcas from './Marcas/OperarioMarcas.vue'
import OperarioTiposServicio from './TiposServicio/OperarioTiposServicio.vue'
import OperarioCompanyias from './compañias/OperarioCompanyias.vue'
import OperarioDocumentacion from './documentacion/OperarioDocumentacion.vue'
import OperarioEspecialidades from './especialidades/OperarioEspecialidades.vue'
//import Dialog from 'primevue/dialog';
export default {
    props: ['id'],
    components: {
        'operario-datos': OperarioDatos,
        'operario-foto': OperarioFoto,
        'operario-marcas': OperarioMarcas,
        'operario-tiposservicio': OperarioTiposServicio,
        'operario-companyias': OperarioCompanyias,
        'operario-documentacion': OperarioDocumentacion,
        'operario-especialidades': OperarioEspecialidades,
    },
    data() {
        return {
            operario: [],
        }
    },
    methods: {
        async obtenerOperario(id) {
            /* get  modulos/pwgsapi/index.php/operarios/:id */
            const api = new PwgsApi;
            const datos = await api.get('operarios/' + id);
            this.operario = datos.datos;
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            //
        },
    },
    mounted() {
        this.obtenerOperario(this.id);
    },
    watch: {
        id() {
            this.obtenerOperario(this.id);
        },
        $route() {
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;
     
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            //
        }
    }
}
</script>
